import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import {LOGIN_MODAL_OPEN} from '@/store/mutation-types'
import getEnv from '@/plugins/env'

axios.defaults.baseURL = getEnv('VUE_APP_API_URL');
axios.defaults.withCredentials = true

axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json',
};

// Response interceptor
axios.interceptors.response.use(response => response, error => {

    if ( ! error.response) {
        return Promise.reject(error)
    }

    const {status, data} = error.response

    switch (status) {
        case 400:
            if (data?.reason === 'email_not_verified') {
                window.vueInstance.openEmailVerificationDialog()
                break;
            }
        case 401:
        case 419:
            window.vueInstance.openLoginDialog()
            break;
        case 403:
        case 422:
        case status.toString().startsWith('5'):
            let message = data.message || 'Server error'
            Vue.toasted.show(message, {
                type: "error",
                duration: 2000,
                position: "top-right"
            });
            break;
    }

    return Promise.reject(error)
})

window.axios = axios
export default axios
